/**
 * 
 * AuthConfig.js - setup for the MSAL.js library in the client. 
 * 
 */

import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

  // Define the base URL
  let baseUrl = window.location.origin;

  // Define your environment-specific redirect URIs
      const redirectUris = {
      'gamma.app.praxisnavigator.io': 'https://gamma.app.praxisnavigator.io/',
      'app.praxisnavigator.io': 'https://app.praxisnavigator.io/',
      'localhost:3000': 'http://localhost:3000/'
      };
  
      console.log('window.location.hostname: ', window.location.hostname);
      console.log("baseUrl: ", baseUrl);
  // Determine the correct redirectUri based on the current hostname
      const redirectUri = redirectUris[window.location.hostname] || baseUrl + "redirect"; 

      
export const msalConfig = {
  
    auth: {
        clientId: process.env.REACT_APP_WEB_CLIENT_ID, 
        authority: 'https://login.microsoftonline.com/organizations', // no consumers allowed.  
        redirectUri: redirectUri, 
        postLogoutRedirectUri: 'https://praxisnavigator.io/', 
        clientCapabilities: ['CP1'],
    },
    cache: {
        cacheLocation: 'sessionStorage', 
        storeAuthStateInCookie: false, 
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
    
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

/**
 * 
 * These probably only makes sense in backend, not in the client. 
 * 
 */
 export const protectedResources = {
    api: {
        endpoint: process.env.REACT_APP_API_CDN_URL + '/api',
        scopes: ['api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/login_user'],
    }, 
     apiProfile: {
        endpoint: process.env.REACT_APP_API_CDN_URL + '/profile',
        scopes: ['api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/access_graph_on_behalf_of_user'],
     },
     apiCompany: {
        endpoint: process.env.REACT_APP_API_CDN_URL + '/companies',
        scopes: ['api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/create_appreg_on_behalf_of_admin'],
    },
    apiConnectorGraphapi: {
        endpoint: process.env.REACT_APP_API_CDN_URL + '/connectors/graphapi',
        scopes: [
            "User.Read.All", 
            "AttackSimulation.Read.All",
            "SecurityAlert.Read.All",
            "SecurityIncident.Read.All",
            "SecurityEvents.Read.All",
            "AuditLog.Read.All",
            "UserAuthenticationMethod.Read.All",
            "Policy.ReadWrite.AuthenticationMethod",
            "Directory.Read.All",
            "Mail.ReadBasic",
            "Organization.Read.All",
            "MultiTenantOrganization.ReadBasic.All",
            "DeviceManagementConfiguration.Read.All",

        ],
    },
 };




    //  apiProfile: {
    //     endpoint: process.env.REACT_APP_API_CDN_URL + '/profile',
    //     scopes: [
    //         'api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/access_graph_on_behalf_of_user',
    //     ],
    //     //scopes: [process.env.REACT_APP_WEB_CLIENT_ID + '/.default']
    //  },
    //  apiCompany: {
    //     endpoint: process.env.REACT_APP_API_CDN_URL + '/companies',
    //     scopes: [
    //         'api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/create_appreg_on_behalf_of_admin',
    //     ],
    // },


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

/**
 * 
 * are these scopes correct? i.e. they are set up against REACT_APP_WEB_CLIENT_ID - which is the 
 * server for management, not the cusotomers own app, which is using the scopes.
 * 
 * Perhaps this must be set up using a admin consent flow instead? 
 * 
 */

export const loginRequest = {
    scopes: [
        'api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/create_appreg_on_behalf_of_admin',
        'api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/access_graph_on_behalf_of_user',
        'api://' + process.env.REACT_APP_WEB_CLIENT_ID + '/run_connector_on_behalf_of_admin',
    ],
    extraScopesToConsent: [ 
        "https://graph.microsoft.com/User.Read.All",
        "https://graph.microsoft.com/Directory.Read.All",
        "https://graph.microsoft.com/Application.ReadWrite.All",
        "https://graph.microsoft.com/AttackSimulation.Read.All",
        "https://graph.microsoft.com/SecurityAlert.Read.All",
        "https://graph.microsoft.com/SecurityIncident.Read.All",
        "https://graph.microsoft.com/SecurityEvents.Read.All",
        "https://graph.microsoft.com/AuditLog.Read.All",
        "https://graph.microsoft.com/UserAuthenticationMethod.Read.All",
        "https://graph.microsoft.com/Policy.ReadWrite.AuthenticationMethod",
        "https://graph.microsoft.com/Mail.ReadBasic",
    ],
    prompt: 'select_account',
    redirectUri: window.location.origin,

}; 


/**
export const loginRequest = {
    scopes: [
        "https://graph.microsoft.com/User.Read.All",
        "https://graph.microsoft.com/Directory.Read.All",
    ],
    prompt: 'select_account',
    redirectUri: window.location.origin,
}; */

export const profileRequest = {
    scopes: [...protectedResources.apiProfile.scopes],
    prompt: 'select_account',
    redirectUri: window.location.origin,
};

export const loginRequestElevated = {
    scopes: [...protectedResources.apiCompany.scopes],
    prompt: 'select_account',
    redirectUri: window.location.origin,
};
